import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/facturas`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  /**
   * Abonos realizados a la factura
   * @param {int} idFactura
   */
  abonosFacturaJSON(idFactura) {
    return apiClient.get(`${idFactura}/abonos.json`)
  },

  actualizar(factura) {
    return apiClient.post(`${factura.id}`, factura)
  },

  cancelar(idFactura, params) {
    params = params || {}
    return apiClient.post(`${idFactura}/cancelar`, params)
  },

  cancelarVarias(idsFac) {
    idsFac = idsFac || []
    return apiClient.post('facturas/cancelar', idsFac)
  },

  duplicar(idFactura) {
    return apiClient.post(`${idFactura}/duplicar`)
  },

  eliminar(idFactura) {
    return apiClient.delete(`${idFactura}`)
  },

  eliminarProrroga(idFactura){
    return apiClient.delete(`${idFactura}/prorroga`)
  },

  facturaJSON(idFactura, params) {
    params = params || {}
    return apiClient.get(`${idFactura}.json`, { params: params })
  },

  facturasJSON(params) {
    return apiClient.get(`${API}/facturas.json`, { params: params })
  },

  fechaVencimiento(idFactura) {
    return apiClient.get(`${API}/facturas/${idFactura}/fecha-vencimiento`)
  },

  generarFacturasAnticipadas(generar) {
    return apiClient.post('generar-facturas-anticipadas', generar)
  },

  guardar(factura) {
    return apiClient.post('', factura)
  },

  guardarProrroga(prorroga, idFactura){
    return apiClient.post(`${idFactura}/prorroga`, prorroga)
  },

  nPagadas(params) {
    params = params || {}
    return apiClient.get('n-pagadas', { params: params })
  },

  nNoPagadas(params) {
    params = params || {}
    return apiClient.get('n-no-pagadas', { params: params })
  },

  pendientesPagoJSON(params) {
    return apiClient.get('pendientes-pago.json', { params: params })
  },

  totalMorosas() {
    return apiClient.get('total-morosas')
  },

  totalPagadasEnMes() {
    return apiClient.get('mes/total-pagadas')
  },

  totalPendientesPagoEnMes() {
    return apiClient.get('mes/total-pendientes-pago')
  },

  vfacturas1JSON(params) {
    params = params || {}
    return apiClient.get(`${API}/vfacturas1.json`, { params: params })
  }
}
